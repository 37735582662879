<template>
  <div class="topbar-item" v-if="currentUser.data">
    <div
      class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
      :class="{ 'btn-clean': !isCustomStyle }"
    >
      <div
        class="px-1"
        :class="{
          'order-2': $vuetify.rtl,
          'order-1': !$vuetify.rtl,
        }"
      >
        <span
          class="font-weight-bold font-size-base d-none d-md-inline mr-1"
          :class="{ 'text-white': isCustomStyle, 'text-muted': !isCustomStyle }"
        >
          {{ $t("hi") }},
        </span>
        <span
          class="font-weight-bolder font-size-base d-none d-md-inline mr-3"
          :class="{
            'text-white': isCustomStyle,
            'text-dark-50': !isCustomStyle,
          }"
        >
          {{ currentUser.data.name }}
        </span>
      </div>
      <span
        class="symbol symbol-35 symbol-light-success"
        :class="{
          'order-1': $vuetify.rtl,
          'order-2': !$vuetify.rtl,
        }"
      >
        <img
          v-if="currentUser.data.photo.url"
          alt="image"
          :src="currentUser.data.photo.url"
        />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.data.photo.first_letter.toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="zindex-5 offcanvas p-8"
      :class="{
        'offcanvas-right': !$vuetify.rtl,
        'offcanvas-left': $vuetify.rtl,
      }"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pt-0"
      >
        <h3 class="font-weight-bold m-0">{{ $t("user_profile") }}</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-secondary"
          id="kt_quick_user_close"
        >
          <v-icon>mdi-close</v-icon>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content scroll"
        style="max-height: 90vh; position: relative"
        :class="{
          'pl-5 ml-5': $vuetify.rtl,
          'pr-5 mr-5': !$vuetify.rtl,
        }"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div
            class="symbol symbol-100"
            :class="{
              'ml-4': $vuetify.rtl,
              'mr-4': !$vuetify.rtl,
            }"
          >
            <img
              v-if="currentUser.data.photo.url"
              :alt="currentUser.data.name"
              :src="currentUser.data.photo.url"
            />
            <span v-else class="symbol symbol-100 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">
                {{ currentUser.data.photo.first_letter.toUpperCase() }}
              </span>
            </span>
          </div>
          <div class="d-flex flex-column mx-0">
            <div
              to="/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              :class="{ 'text-right': $vuetify.rtl }"
            >
              {{ currentUser.data.name }}
            </div>
            <div
              class="text-muted mt-0"
              :class="{ 'text-right': $vuetify.rtl }"
            >
              {{ organization }}
            </div>
            <div class="navi mt-0" :class="{ 'text-right': $vuetify.rtl }">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-0">
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.data.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn--export-filter" @click="onLogout">
              {{ $t("sign_out") }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            to="/profile"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div
                class="navi-text"
                :class="{ 'text-right pr-4': $vuetify.rtl }"
              >
                <router-link to="/profile">
                  <div class="font-weight-bold text-dark-75">
                    {{ $t("my_profile") }}
                  </div>
                </router-link>
                <div class="text-muted">
                  {{ $t("account_settings_and_more") }}
                  <span
                    class="label label-light-primary label-inline font-weight-bold"
                  >
                    {{ $t("update") }}
                  </span>
                </div>
              </div>
            </div>
          </router-link>
          <!-- <div class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <v-icon>mdi-flag-outline</v-icon>
                  </span>
                </div>
              </div>
              <div
                class="navi-text"
                :class="{ 'text-right pr-4': $vuetify.rtl }"
              >
                <div>
                  <v-select
                    :label="$t('language')"
                    v-model="language"
                    :items="languages"
                    item-value="index"
                    item-text="text"
                    outlined
                    dense
                    hide-details
                    @change="setLocale"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <button @click="clearCache" class="navi-item w-100 text-left">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Duplicate.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div
                class="navi-text px-1"
                :class="{ 'text-right pr-4': $vuetify.rtl }"
              >
                <div>
                  <div class="font-weight-bold text-dark-75">
                    {{ $t("clear_cache") }}
                  </div>
                </div>
                <!--                <div class="text-muted">-->
                <!--                  Account settings and more-->
                <!--                </div>-->
              </div>
            </div>
          </button>
          <button
            @click="$router.push('/my_reports')"
            class="navi-item w-100 text-left"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-primary svg-icon-2x"
                    ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo8/dist/../src/media/svg/icons/Shopping/Sort3.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M18.5,6 C19.3284271,6 20,6.67157288 20,7.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 C17.6715729,20 17,19.3284271 17,18.5 L17,7.5 C17,6.67157288 17.6715729,6 18.5,6 Z M12.5,11 C13.3284271,11 14,11.6715729 14,12.5 L14,18.5 C14,19.3284271 13.3284271,20 12.5,20 C11.6715729,20 11,19.3284271 11,18.5 L11,12.5 C11,11.6715729 11.6715729,11 12.5,11 Z M6.5,15 C7.32842712,15 8,15.6715729 8,16.5 L8,18.5 C8,19.3284271 7.32842712,20 6.5,20 C5.67157288,20 5,19.3284271 5,18.5 L5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z"
                          fill="#000000"
                        />
                      </g></svg
                  ></span>
                </div>
              </div>
              <div
                class="navi-text px-1"
                :class="{ 'text-right pr-4': $vuetify.rtl }"
              >
                <div>
                  <div class="font-weight-bold text-dark-75">
                    {{ $t("my_reports") }}
                  </div>
                </div>
                <!--                <div class="text-muted">-->
                <!--                  Account settings and more-->
                <!--                </div>-->
              </div>
            </div>
          </button>
          <button
            @click="$router.push('/uploads')"
            class="navi-item w-100 text-left"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-primary svg-icon-2x"
                    ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo8/dist/../src/media/svg/icons/Files/Import.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <title>Stockholm-icons / Files / Import</title>
                      <desc>Created with Sketch.</desc>
                      <defs />
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(12.000000, 7.000000) rotate(-180.000000) translate(-12.000000, -7.000000) "
                          x="11"
                          y="1"
                          width="2"
                          height="12"
                          rx="1"
                        />
                        <path
                          d="M17,8 C16.4477153,8 16,7.55228475 16,7 C16,6.44771525 16.4477153,6 17,6 L18,6 C20.209139,6 22,7.790861 22,10 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,9.99305689 C2,7.7839179 3.790861,5.99305689 6,5.99305689 L7.00000482,5.99305689 C7.55228957,5.99305689 8.00000482,6.44077214 8.00000482,6.99305689 C8.00000482,7.54534164 7.55228957,7.99305689 7.00000482,7.99305689 L6,7.99305689 C4.8954305,7.99305689 4,8.88848739 4,9.99305689 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,10 C20,8.8954305 19.1045695,8 18,8 L17,8 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        />
                        <path
                          d="M14.2928932,10.2928932 C14.6834175,9.90236893 15.3165825,9.90236893 15.7071068,10.2928932 C16.0976311,10.6834175 16.0976311,11.3165825 15.7071068,11.7071068 L12.7071068,14.7071068 C12.3165825,15.0976311 11.6834175,15.0976311 11.2928932,14.7071068 L8.29289322,11.7071068 C7.90236893,11.3165825 7.90236893,10.6834175 8.29289322,10.2928932 C8.68341751,9.90236893 9.31658249,9.90236893 9.70710678,10.2928932 L12,12.5857864 L14.2928932,10.2928932 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        />
                      </g></svg
                    ><!--end::Svg Icon--></span
                  >
                </div>
              </div>
              <div
                class="navi-text px-1"
                :class="{ 'text-right pr-4': $vuetify.rtl }"
              >
                <div>
                  <div class="font-weight-bold text-dark-75">
                    {{ $t("import_data") }}
                  </div>
                </div>
                <!--                <div class="text-muted">-->
                <!--                  Account settings and more-->
                <!--                </div>-->
              </div>
            </div>
          </button>
          <!--end:Item-->
        </div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { MAKE_DEFAULT_HAS_THEME_SET } from "@/core/services/store/main.module";

export default {
  name: "KTQuickUser",
  props: ["customStyle"],
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$store.commit(MAKE_DEFAULT_HAS_THEME_SET, 0);
        this.$router.push({ name: "login" });
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    clearCache() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("profile/clear/cache")
        .then(() => {
          SwalService.successMessage({
            html: this.$t("cache_cleared_successfully"),
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    isCustomStyle: function () {
      return this.customStyle ? true : false;
    },
    ...mapGetters(["currentUser"]),
    organization: function () {
      return this.$store.getters.currentUser.data.organization_name;
    },
  },
};
</script>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>
