<template>
  <ul class="navi navi-hover pa-0 ma-0 zindex-5">
    <template v-for="item in languages">
      <li
        class="ma-0 pa-0 py-1 navi-item"
        :class="{ 'navi-item-active': isActiveLanguage(item.abbreviation) }"
        :key="item.id"
      >
        <a
          text
          href="#"
          class="navi-link ma-0 pa-0"
          v-bind:data-lang="item.abbreviation"
          v-on:click="selectedLanguage"
        >
          <span
            class="symbol symbol-35"
            :class="[
              item.abbreviation === 'ar'
                ? 'symbol-light-warning'
                : 'symbol-light-danger',
            ]"
          >
            <span class="symbol-label font-size-h5 font-weight-bold">
              {{ item.abbreviation.toUpperCase() }}
            </span>
          </span>
          <span class="navi-text px-2">{{ item.name }}</span>
        </a>
      </li>
    </template>
  </ul>
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTDropdownLanguage",
  data() {
    return {
      languages: i18nService.languageslist,
    };
  },
  mounted() {
    i18nService.setActiveLanguage(i18nService.getActiveLanguage());
    this.$vuetify.current = i18nService.getActiveLanguage();
    this.$vuetify.rtl = i18nService.isRTL();
  },
  methods: {
    selectedLanguage(e) {
      const el = e.target.closest(".navi-link");
      const lang = el.getAttribute("data-lang");

      i18nService.setActiveLanguage(lang);
      this.$vuetify.current = lang;
      this.$vuetify.rtl = i18nService.isRTL();
      this.$emit(
        "language-changed",
        this.languages.find((val) => {
          return val.abbreviation === lang;
        })
      );

      // window.location.reload();
    },
    isActiveLanguage(current) {
      return this.activeLanguage === current;
    },
  },
  computed: {
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
  },
};
</script>
