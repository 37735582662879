<template>
  <!-- begin:: Aside -->
  <div
    :class="{
      'aside-left': !$vuetify.rtl,
      'aside-right': $vuetify.rtl,
    }"
    class="aside aside-fixed d-flex flex-column flex-row-auto h-100"
    id="kt_aside"
    ref="kt_aside"
  >
    <!-- @mouseover="mouseEnter" -->
    <!-- @mouseleave="mouseLeave" -->
    <!-- begin:: Aside -->
    <KTBrand></KTBrand>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div
      class="aside-menu-wrapper flex-column-fluid menu-mobile"
      id="kt_aside_menu_wrapper"
    >
      <div
        ref="kt_aside_menu"
        id="kt_aside_menu"
        class="aside-menu my-4"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        :class="asideMenuClass"
      >
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 100vh; position: relative"
        >
          <KTMenu></KTMenu>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="aside-nav menu-desktop position-relative">
      <div
        v-if="showScrollUpButton"
        class="w-100 d-flex justify-center zindex-5 position-absolute top-0 left-0"
        style="background-color: #151b2c; height: 34px"
        @mouseover="() => handleScrollUpHover()"
      >
        <span class="svg-icon ml-5">
          <v-icon size="34" color="#fafafa">mdi-chevron-up</v-icon>
        </span>
      </div>
      <!--begin::Nav-->
      <ul
        @scroll="() => handleShowScrollButtons()"
        class="scrolling text-center py-6 position-relative overflow-y-hidden"
        style="
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
        "
      >
        <!--begin::Item-->
        <component
          v-bind:is="sidebar"
          v-for="menu in menus"
          :key="menu.name"
          :item="menu"
        ></component>
        <!--end::Item-->
      </ul>
      <!--end::Nav-->
      <div
        v-if="showScrollDownButton"
        class="w-100 d-flex justify-center zindex-5 position-absolute bottom-0 left-0"
        style="background-color: #151b2c; height: 34px"
        @mouseover="() => handleScrollDownHover()"
      >
        <span class="svg-icon ml-5">
          <v-icon size="34" color="#fafafa">mdi-chevron-down</v-icon>
        </span>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from "vuex";
import KTBrand from "@/view/layout/brand/Brand.vue";

import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import SidebarMain from "@/own/components/menu/SidebarMain.vue";
// import SPLSidebar from "@/own/components/menu/SPLSidebar.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
import { SET_PREVIOUS_SELECTED_MENU } from "@/core/services/store/sidebar.module";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      subMenuState: false,
      showScrollDownButton: false,
      showScrollUpButton: false,
    };
  },
  components: {
    KTBrand,
    KTMenu,
    // SidebarMain,
    // SPLSidebar,
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      this.$store.commit(
        SET_PREVIOUS_SELECTED_MENU,
        this.$route.name.split(".")[0]
      );

      this.handleShowScrollDownButton(1000);
    });
    window.addEventListener("resize", this.handleShowScrollDownButton);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleShowScrollDownButton);
  },
  watch: {
    $route() {
      this.$store.commit(
        SET_PREVIOUS_SELECTED_MENU,
        this.$route.name.split(".")[0]
      );
    },
  },
  methods: {
    handleScrollDownHover() {
      const element = document.querySelector(".scrolling");
      setTimeout(() => {
        this.showScrollDownButton = false;
        this.showScrollUpButton = true;
      }, 300);
      element.scroll({ top: element?.scrollHeight, behavior: "smooth" });
    },
    handleShowScrollDownButton(ms = 300) {
      setTimeout(() => {
        let menuHeight = document.querySelector(".scrolling")?.scrollHeight;
        this.showScrollDownButton =
          menuHeight > document.documentElement.clientHeight * 0.89;
      }, ms);
    },
    handleScrollUpHover() {
      const element = document.querySelector(".scrolling");
      setTimeout(() => {
        this.showScrollDownButton = true;
        this.showScrollUpButton = false;
      }, 300);
      element.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    handleShowScrollButtons() {
      const element = document.querySelector(".scrolling");
      this.showScrollUpButton = element.scrollTop > 10;
      let scrollBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;
      this.showScrollDownButton = scrollBottom > 20;
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains("aside-fixed")) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm);
            this.outsideTm = null;
          }

          // if the left aside menu is minimized
          if (document.body.classList.contains("aside-minimize")) {
            // document.body.classList.add("aside-minimize-hover");
            // show the left aside menu
            // document.body.classList.remove("aside-minimize");
          }
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        if (document.body.classList.contains("aside-fixed")) {
          if (this.insideTm) {
            clearTimeout(this.insideTm);
            this.insideTm = null;
          }

          if (document.querySelector(".aside-menu .scroll")) {
            document.querySelector(".aside-menu .scroll").scrollTop = 0;
          }

          // if the left aside menu is expand
          if (document.body.classList.contains("aside-minimize-hover")) {
            // hide back the left aside menu
            document.body.classList.remove("aside-minimize-hover");
            document.body.classList.add("aside-minimize");
          }
        }
      }
    },
  },
  computed: {
    sidebar: function () {
      // if (
      //   this.$store.getters.currentUser.data.organization_system_code === "SPL"
      // )
      //   return SPLSidebar;
      // else return SidebarMain;
      return SidebarMain;
    },
    menus: function () {
      const menus = this.$store.getters.currentUser.menu.map((item) => {
        if (item.type === "default") return item;
        else {
          return { ...item, entities: [...item.entities] };
        }
      });
      // console.log(menus);
      return menus.filter((item) => item !== undefined);
    },
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>

<style scoped>
@media (min-width: 991px) {
  .aside-right {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
  }
}

.menu-desktop {
  display: block !important;
  background-color: #151b2c !important;
  height: 100%;
}

.scrolling {
  max-height: 89vh;
  overflow-y: hidden;
  overflow-x: hidden;
  /* overflow-style: marquee-line; */
  margin-bottom: 0;
}

.scrolling::-webkit-scrollbar {
  display: none;
}

.menu-mobile {
  display: none !important;
  z-index: 0;
}

@media screen and (max-width: 990px) {
  .menu-desktop {
    display: none !important;
  }

  .menu-mobile {
    display: block !important;
  }
}
</style>
