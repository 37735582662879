<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center mobi-head"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <router-link :to="{ name: 'dashboard.dashboardAnalytics' }">
      <img alt="Logo" width="48" :src="headerLogo" />
    </router-link>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon ma-2 mr-4"
        id="kt_aside_mobile_toggle"
        ref="mobiAsideToggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <button
        class="btn btn-hover-text-primary p-0 ml-2"
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
      >
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
          <inline-svg
            class="svg-icon"
            src="/media/svg/icons/General/User.svg"
          />
          <!--end::Svg Icon-->
        </span>
      </button>
      <!--end::Topbar Mobile Toggle-->

      <!--begin: User Bar -->
      <!-- <div class="ma-2">
        <KTQuickUserMobi v-if="getCurrentUser"></KTQuickUserMobi>
      </div> -->
      <!--end: User Bar -->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import eventBus from "@/core/services/eventBus";
import { mapGetters } from "vuex";
// import KTQuickUserMobi from "@/view/layout/extras/offcanvas/QuickUserMobi.vue";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "KTHeaderMobile",
  components: {
    //  KTQuickUserMobi
  },
  mounted() {
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  methods: {
    toggleMobiAside() {
      this.$refs.mobiAsideToggle.click();
    },
  },
  created() {
    eventBus.$on("toggleAside", () => {
      this.toggleMobiAside();
    });
  },
  beforeDestroy() {
    eventBus.$off("toggleAside");
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return this.$store.getters.currentUser.theme.logo;
      // return "/img/iqplatform.png";
    },

    getCurrentUser() {
      return this.$store.getters.currentUser.data;
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
  },
};
</script>
<style scoped>
.mobi-head {
  background-color: #1a2235;
  z-index: 2000;
}
</style>
