<template>
  <!--  <v-dialog persistent width="400" v-model="mode" class="elevation-0 ma-0">-->
  <!--    <v-card color="primary" elevation="">-->
  <!--      <v-card-text class="pa-2">-->
  <!--        <h6 class="white&#45;&#45;text">Processing ...</h6>-->
  <!--        &lt;!&ndash;        <v-progress-linear&ndash;&gt;-->
  <!--        &lt;!&ndash;          :indeterminate="true"&ndash;&gt;-->
  <!--        &lt;!&ndash;          color="white"&ndash;&gt;-->
  <!--        &lt;!&ndash;        ></v-progress-linear>&ndash;&gt;-->
  <!--        <div-->
  <!--          style="display: flex; align-content: center; justify-content: center; align-items: center; overflow: hidden; background-color: transparent !important;"-->
  <!--        >-->
  <!--          <b-spinner-->
  <!--            style="width: 6rem; height: 6rem; background-color: transparent !important;"-->
  <!--            variant="white"-->
  <!--          ></b-spinner>-->
  <!--        </div>-->
  <!--      </v-card-text>-->
  <!--    </v-card>-->
  <!--  </v-dialog>-->
  <loader
    v-if="status"
    :object="$store.getters.currentUser.theme['text_color']"
    color1="#ffffff"
    color2="#17fd3d"
    size="6"
    speed="1"
    bg="#160731"
    objectbg="#999793"
    opacity="50"
    disableScrolling="true"
    name="spinning"
  ></loader>
</template>

<script>
export default {
  name: "PageLoader",
  props: ["mode"],
  data: () => ({
    status: false,
  }),
  watch: {
    mode: function (val) {
      if (val) {
        this.status = true;
        // setTimeout(() => {
        //   this.status = false;
        // }, 16000);
      } else {
        this.status = false;
      }
    },
  },
};
</script>
