<template>
  <!-- begin:: Aside -->
  <div
    class="brand flex-column-auto p-2"
    style="z-index: 2 !important"
    :style="{
      'background-color':
        this.$store.getters.currentUser.data.organization_system_code !== 'SPL'
          ? '#151b2c !important'
          : '#ffffff !important',
    }"
  >
    <div class="brand-logo" width="100%">
      <router-link :to="{ name: 'dashboard.dashboardAnalytics' }">
        <img
          class="d-block"
          width="100%"
          :src="$store.getters.currentUser.theme.logo"
          alt="Logo"
        />
      </router-link>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
export default {
  name: "KTBrand",
};
</script>
